.card_popup {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 20px 20px 0 0;
  gap: 24px;
  padding: 24px;
  position: relative;
  overflow: auto;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    :global {
      .swiper {
        width: 100%;
        height: 100%;
      }

      .swiper-container {
        width: 100%;
      }
    }
  }

  &__image {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    height: 212px;
  }

  &__text {
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: center;
    text-align: center;
    word-break: break-word;

    &__title {
      color: var(--secondary-1000);
    }

    &__description {
      text-align: center;
      color: var(--secondary-600);
    }
  }
}
