.balance {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  border-radius: 24px;
  background: url(./background.png);
  box-shadow: var(--shadow-balance);
  width: 100%;
  gap: 20px;

  &__title {
    color: var(--white);
  }

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    padding: 14px;
    border-radius: 14px;
    width: 100%;
    background: var(--white);

    .divider {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      min-width: 10px;

      &__line {
        width: 1px;
        height: 30px;
        background: var(--secondary-200);
      }
    }
  }
}
