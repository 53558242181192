.container {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  overflow: hidden;
  z-index: 900;

  &.in.open .modal {
    transform: translateY(0) scale(1);
    opacity: 1;
  }

  &.in.open .backdrop {
    visibility: visible;
    opacity: 1;
    pointer-events: auto;
    z-index: 999;
  }
}

.modal {
  position: absolute;
  z-index: 1000;
  transition:
    transform var(--transition-speed) ease-out,
    opacity var(--transition-speed) ease-out;
  opacity: 0;
  background: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;

  &__header {
    &_common {
      display: flex;
      padding: 16px 16px 8px 24px;
      align-items: center;
      gap: 20px;
      align-self: stretch;
      justify-content: space-between;
    }

    &_back {
      display: flex;
      padding: 20px 16px;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 20px;
    }
  }

  .close {
    position: absolute;
    z-index: 2;
    top: 12px;
    right: 12px;
    height: 32px;
    padding: 4px;
    color: var(--secondary-700);
    transition: background-color var(--transition-speed) ease-out;
    background-color: var(--secondary-100);
    border-radius: 12px;
    cursor: pointer;

    &:active {
      background-color: var(--secondary-200);
    }
  }

  &__content {
    display: flex;
    height: fit-content;
    max-height: calc(100vh - 10px);
    width: 100%;
    justify-content: center;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .swipe_rectangle {
    position: absolute;
    width: 40px;
    height: 2px;
    background: var(--secondary-250);
    border-radius: 100px;
    top: 11px;
    left: auto;
    right: auto;
  }
}

.backdrop {
  transition: opacity var(--transition-speed) ease-out;
  opacity: 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  pointer-events: none;
  z-index: 0;

  &:not(.transparent) {
    background: var(--default-backdrop);
  }
}
