.order_page {
  display: flex;
  flex-direction: column;
  gap: 24px;

  &__title {
    &__text {
      color: var(--secondary-1000);

      &__count {
        color: var(--primary-900);
        margin-left: 14px;
      }
    }
  }
}
