.balance {
  display: flex;
  gap: 16px;
  align-items: center;

  .item {
    display: flex;
    align-items: center;
    gap: 4px;
    color: var(--secondary-600);
  }
}
