.history_details {
  border-radius: 30px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  gap: 24px;

  &__title {
    color: var(--secondary-1000);
    text-align: center;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .amount,
    .date,
    .thanks {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    .label {
      color: var(--secondary-600);
    }

    .description {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      word-break: break-word;

      p {
        max-height: 100px;
        overflow: auto;
        scrollbar-width: none;

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }

    .currency {
      display: flex;
      align-items: center;
      padding: 4px 10px;
      border-radius: 100px;
      background-color: var(--secondary-150);
      gap: 4px;

      &.positive {
        > span {
          color: var(--additional-green);
        }
      }

      &.negative {
        > span {
          color: var(--additional-red);
        }
      }
    }
  }

  &__actions {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .button {
      height: 54px;
      background-color: var(--secondary-150);
      color: var(--secondary-1000);

      &:hover,
      &:active {
        background-color: var(--secondary-250);
      }
    }
  }
}
