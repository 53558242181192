.back_button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 52px;
  width: 52px;
  border-radius: 12px;
  background-color: var(--secondary-150);
  transition: background-color var(--transition-speed) ease;

  & > svg > g > path {
    stroke: var(--secondary-600);
    transition: stroke var(--transition-speed) ease-out;
  }

  &:active {
    background-color: var(--secondary-250);

    & > svg > g > path {
      stroke: var(--secondary-700);
    }
  }
}
