.item_card_button {
  background-color: var(--secondary-100);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;

  .text {
    color: var(--secondary-1000);
  }

  &:active {
    background-color: var(--secondary-200);
  }
}
