.shop_page {
  display: flex;
  flex-direction: column;

  .top {
    position: fixed;
    background: var(--white);
    top: 0;
    left: 0;
    right: 0;
  }

  .balance {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 24px;
    padding: 20px 16px 0;
  }
}

.card_popup {
  &__modal {
    bottom: 0;
    left: 0;
    right: 0;
    height: auto;
    max-height: 100vh;
    transform: translateY(100%);
    border-radius: 20px 20px 0 0;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.order_popup {
  &__modal {
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    margin: auto;
    height: fit-content;
    max-width: 270px;
    width: 100%;
    border-radius: 30px;
    box-shadow: var(--shadow-modal);
  }
}
