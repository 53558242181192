.button {
  background-color: var(--secondary-100);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--secondary-1000);
  padding: 18px;
  min-height: initial;

  .text {
    &_description {
      margin-right: 6px;
    }

    &_price {
      margin-right: 4px;
    }
  }

  &:active {
    background-color: var(--secondary-200);
  }
}
