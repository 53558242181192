.shop_card {
  padding: 10px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  word-break: break-word;
  background: var(--white);
  width: 100%;
  cursor: pointer;

  &__image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 100px;
    margin: 0 auto;

    .img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4px;
    text-align: center;

    &__title {
      color: var(--secondary-1000);
    }

    &__description {
      color: var(--secondary-600);
      overflow: hidden;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      text-align: center;
    }
  }
}
