.order_status {
  display: flex;
  gap: 10px;

  &__badge {
    padding: 0px 10px;
    height: 22px;
    gap: 4px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    &.CANCELED {
      background: var(--additional-badges-red-light);
      color: var(--additional-red);
    }

    &.IN_PROGRESS {
      background: var(--additional-badges-blue-light);
      color: var(--additional-badges-blue);
    }

    &.COMPLETED {
      background: var(--additional-badges-green-light);
      color: var(--additional-badges-green);
    }

    &.PENDING {
      background: var(--secondary-150);
      color: var(--secondary-750);
    }
  }

  &__tooltip {
    background: var(--secondary-600) !important;
    max-width: calc(100% - 10px);
    word-wrap: break-word;
    opacity: 1 !important;
  }
}
