@font-face {
  font-family: 'NT Somic';
  src:
    url('../fonts/NTSomic-Regular.woff2') format('woff2'),
    url('../fonts/NTSomic-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'NT Somic';
  src:
    url('../fonts/NTSomic-Medium.woff2') format('woff2'),
    url('../fonts/NTSomic-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'NT Somic';
  src:
    url('../fonts/NTSomic-Bold.woff2') format('woff2'),
    url('../fonts/NTSomic-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('../fonts/SF-Pro-Display-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('../fonts/SF-Pro-Display-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('../fonts/SF-Pro-Display-Semibold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
}
