.order_item {
  display: flex;
  justify-content: flex-start;
  padding: 12px 14px;
  border-radius: 16px;
  gap: 10px;
  background: var(--white);
  box-shadow: var(--shadow-default);

  &__left {
    .image {
      width: 72px;
      height: 64px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  &__right {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 10px;

    .text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 10px;
      flex: 1 1 0;
      height: 100%;

      &__heading {
        display: flex;
        gap:  8px;
      }

      &__comment {
        &__image {
          color: var(--secondary-700);
        }

        &__tooltip {
          background: var(--secondary-600) !important;
          max-width: calc(100% - 10px);
          word-wrap: break-word;
          opacity: 1 !important;
        }
      }

      &__title {
        color: var(--secondary-1000);
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      &__block {
        display: flex;
        align-items: center;
        color: var(--secondary-600);
        gap: 6px;
      }

      &__emmoji {
        font-size: 12px;
        margin-left: 4px;
      }

      &__ellipse {
        display: flex;
        align-items: center;
      }
    }

    .status {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &__info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      gap: 10px;

      &__id {
        color: var(--secondary-400);
      }
    }
  }
}
