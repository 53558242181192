.home_page {
  display: flex;
  flex-direction: column;
  gap: 24px;

  &__buttons {
    display: flex;
    gap: 10px;

    &__order {
      padding: 23px 24px;
    }
  }
}
