.card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0;
  gap: 8px;
  opacity: 1;
  transition: opacity var(--transition-speed) ease-out;
  cursor: pointer;

  &:not(:last-child) {
    border-bottom: 1px solid var(--secondary-150);
  }

  &:hover,
  &:active {
    opacity: 0.6;
  }

  &__left {
    display: flex;
    gap: 10px;
    width: 100%;
    word-break: break-word;

    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 32px;
      width: 32px;
      min-width: 32px;
      background: var(--secondary-150);
      border-radius: 8px;
    }

    .text {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      width: 100%;
      word-break: break-word;

      &__title {
        color: var(--secondary-900);
      }

      &__description {
        color: var(--secondary-500);
      }
    }
  }

  &__right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    width: 60px;

    .amount {
      &__positive {
        color: var(--additional-green);
      }

      &__negative {
        color: var(--additional-red);
      }
    }

    .date {
      color: var(--secondary-500);
    }
  }
}
