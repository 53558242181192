.button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 100%;
  min-height: 40px;
  border-radius: 12px;
  transition:
    background-color var(--transition-speed) ease-out,
    color var(--transition-speed) ease-out;
}
