.history {
  border-radius: 20px;
  padding: 14px;
  background: var(--white);
  box-shadow: var(--shadow-default);

  &__title {
    color: var(--secondary-1000);
    margin-bottom: 20px;
  }

  &__spinner {
    height: 57px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.transaction__modal {
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  margin: auto;
  height: auto;
  max-height: 400px;
  max-width: 350px;
  width: 100%;
  border-radius: 30px;
  box-shadow: var(--shadow-modal);
}
