.order_popup {
  border-radius: 30px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  gap: 24px;

  .content {
    display: flex;
    flex-direction: column;
  }

  .title {
    color: var(--secondary-1000);
    text-align: center;

    &__initial {
      display: flex;
      justify-content: flex-start;
      text-align: left;
    }
  }

  .description {
    color: var(--secondary-600);
    text-align: center;

    &__initial {
      text-align: left;
    }
  }

  .emoji_block {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 30px 0 34px;
    border-radius: 20px;
    background: var(--secondary-150);

    &_text {
      font-size: var(--font-size-60);
      line-height: var(--line-height-26);
    }
  }

  .initial_text_block {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__right {
      background: var(--secondary-150);
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 4px;
      border-radius: 100px;
      padding: 4px 10px;
    }

    &_price {
      color: var(--primary-1000);
    }
  }

  .actions {
    display: flex;
    flex-direction: column;
    gap: 10px;

    &__button {
      height: 54px;
    }
  }
}
