.currency {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  gap: 4px;

  &_text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &_price {
      color: var(--secondary-1000);
      font-weight: var(--font-weight-500);
    }

    &_currency_name {
      color: var(--secondary-600);
    }
  }
}
