.shop_button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: var(--grad-green_grad);
  border-radius: var(--border-radius-16);
  min-height: 60px;
  transition: background-color var(--transition-speed) ease-out;

  &:active {
    background: var(--grad-green_grad-active);
  }
}

.content {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 16px;

  &_icon {
    width: 100%;
    max-width: 55px;
    max-height: 60px;

    img {
      object-fit: contain;
    }
  }

  &_text {
    color: var(--white);
    flex: 1 1 100%;
    display: flex;
    justify-content: center;
  }
}
