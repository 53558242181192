.button {
  border-radius: 12px;
  border: none;
  transition: background-color var(--transition-speed) ease-out;

  &__link {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.primary {
    background-color: var(--secondary-1000);
    color: var(--white);

    &:active {
      background-color: var(--primary-900);
    }
  }

  &.secondary {
    background-color: var(--secondary-150);
    color: var(--secondary-1000);

    &:active {
      background-color: var(--secondary-250);
    }
  }

  &.green {
    background-color: var(--white);
    color: var(--green);
    box-shadow: var(--shadow-default);

    &:active {
      background-color: var(--secondary-150);
    }
  }
}
