.shop_page_content {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-top: 140px;

  .category {
    display: flex;
    flex-direction: column;
    gap: 10px;

    &__title {
      color: var(--secondary-600);
    }
  }

  .products {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-gap: 10px;
  }
}
