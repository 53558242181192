@import 'styles/global.scss';

.h1 {
  @include h1;
}

.h2 {
  @include h2;
}

.h3 {
  @include h3;
}

.h4 {
  @include h4;
}

.subtitle-1 {
  @include subtitle-1;
}

.subtitle-2 {
  @include subtitle-2;
}

.subtitle-3 {
  @include subtitle-3;
}

.subtitle-4 {
  @include subtitle-4;
}

.body-1 {
  @include body-1;
}

.body-2 {
  @include body-2;
}

.title-1 {
  @include title-1;
}

.title-2 {
  @include title-2;
}

.main-balance-emoji {
  @include main-balance-emoji;
}

.body-2 {
  @include body-2;
}

.shop-balance-emoji {
  @include shop-balance-emoji;
}
