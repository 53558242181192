.categories {
  display: flex;
  gap: 6px;
  overflow-x: auto;
  overflow-y: hidden;
  margin-bottom: 24px;

  &__list {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow: auto;
    gap: 8px;
    background-color: var(--base-1);
    padding: 0 16px;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    background: var(--secondary-100);
    border-radius: 30px;
    padding: 0 16px;
    cursor: pointer;
    transition:
      background-color var(--transition-speed) ease-out,
      color var(--transition-speed) ease-out;

    > span {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    &:global {
      &.active {
        background-color: var(--primary-900);
        color: var(--white);
      }
    }
  }
}
