@use 'reset.scss';
@use 'fonts.scss';
@import 'swiper/css';

:root {
  --transition-speed: 0.3s;

  /* Primary colors */
  --primary-900: #ff5e2f;
  --white: #ffffff;
  --green: #42ac68;

  /* Secondary colors */
  --secondary-100: #f6f6f7;
  --secondary-150: #ecedef;
  --secondary-200: #e3e4e7;
  --secondary-250: #d9dbdf;
  --secondary-400: #bdc0c8;
  --secondary-500: #aaaeb8;
  --secondary-600: #90959e;
  --secondary-700: #70737b;
  --secondary-750: #60636a;
  --secondary-800: #303135;
  --secondary-900: #202123;
  --secondary-1000: #101012;

  /* Gradient colors */
  --grad-green_grad: linear-gradient(270deg, #5bc580 0%, #2f9956 100%);
  --grad-green_grad-active: linear-gradient(
      0deg,
      rgba(16, 16, 18, 0.3) 0%,
      rgba(16, 16, 18, 0.3) 100%
    ),
    linear-gradient(270deg, #5bc580 0%, #2f9956 100%);
  --grad-orange_grad: linear-gradient(106deg, #ff5e2f 20.13%, #ff754d 86.94%);
  --grad-grey: linear-gradient(180deg, #fff 0%, #e3e4e7 100%);

  /* Additional colors */
  --additional-green: #00c22b;
  --additional-red: #f82121;

  /* Additional badges colors */
  --additional-badges-green: #22a56b;
  --additional-badges-green-light: #dbf7eb;
  --additional-badges-red: #db4356;
  --additional-badges-red-light: #ffe7ea;
  --additional-badges-blue: #428cf6;
  --additional-badges-blue-light: #e5eeff;

  /* Backdrop */
  --default-backdrop: rgba(80, 83, 88, 0.7);

  /* Fonts */
  --default-font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI (Custom)',
    Roboto, 'Helvetica Neue', 'Open Sans (Custom)', system-ui, sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji';
  --font-family-nt: 'NT Somic';
  --font-family-sf: 'SF Pro Display';
  --default-font-style: normal;
  --font-weight-400: 400;
  --font-weight-500: 500;
  --font-weight-600: 600;
  --font-size-10: 10px;
  --font-size-12: 12px;
  --font-size-14: 14px;
  --font-size-16: 16px;
  --font-size-18: 18px;
  --font-size-20: 20px;
  --font-size-30: 30px;
  --font-size-36: 36px;
  --font-size-60: 60px;
  --line-height-10: 10px;
  --line-height-12: 12px;
  --line-height-14: 14px;
  --line-height-16: 16px;
  --line-height-20: 20px;
  --line-height-24: 24px;
  --line-height-26: 26px;
  --line-height-27: 27px;
  --line-height-28: 28px;
  --line-height-36: 36px;
  --line-height-40: 40px;
  --letter-spacing-0-32: -0.32px;
  --letter-spacing-0-40: -0.4px;
  --letter-spacing-0-48: -0.48px;
  --letter-spacing-0-56: -0.56px;
  --letter-spacing-0-64: -0.64px;
  --letter-spacing-0-72: -0.72px;
  --letter-spacing-0-8: -0.8px;
  --letter-spacing-1-2: -1.2px;
  --letter-spacing-1-44: -1.44px;

  /* Border radius */
  --border-radius-16: 16px;

  /* Shadows */
  --shadow-default: 0px 8px 20px 0px rgba(112, 115, 123, 0.08);
  --shadow-balance: 0px 4px 30px 0px rgba(255, 94, 47, 0.4);
  --shadow-modal: 0px 24px 24px 0px rgba(16, 16, 18, 0.15);
}

:global {
  .swiper {
    --swiper-navigation-color: var(--primary-900);
    --swiper-preloader-color: var(--primary-900);
  }
}

/* Typography */
@mixin h1 {
  font-family: var(--font-family-nt);
  font-size: var(--font-size-36);
  font-style: var(--default-font-style);
  font-weight: var(--font-weight-400);
  line-height: var(--line-height-36);
  letter-spacing: var(--letter-spacing-1-44);
}

@mixin h2 {
  font-family: var(--font-family-nt);
  font-size: var(--font-size-30);
  font-style: var(--default-font-style);
  font-weight: var(--font-weight-400);
  line-height: var(--line-height-40);
  letter-spacing: var(--letter-spacing-1-2);
}

@mixin h3 {
  font-family: var(--font-family-nt);
  font-size: var(--font-size-30);
  font-style: var(--default-font-style);
  font-weight: var(--font-weight-400);
  line-height: var(--line-height-28);
  letter-spacing: var(--letter-spacing-1-2);
}

@mixin h4 {
  font-family: var(--font-family-sf);
  font-size: var(--font-size-20);
  font-style: var(--default-font-style);
  font-weight: var(--font-weight-500);
  line-height: var(--line-height-24);
  letter-spacing: var(--letter-spacing-0-8);
}

@mixin subtitle-1 {
  font-family: var(--font-family-nt);
  font-size: var(--font-size-20);
  font-style: var(--default-font-style);
  font-weight: var(--font-weight-400);
  line-height: var(--line-height-24);
  letter-spacing: var(--letter-spacing-0-40);
}

@mixin subtitle-2 {
  font-family: var(--font-family-nt);
  font-size: var(--font-size-16);
  font-style: var(--default-font-style);
  font-weight: var(--font-weight-400);
  line-height: var(--line-height-14);
  letter-spacing: var(--letter-spacing-0-64);
}

@mixin subtitle-3 {
  font-family: var(--font-family-sf);
  font-size: var(--font-size-16);
  font-style: var(--default-font-style);
  font-weight: var(--font-weight-600);
  line-height: var(--line-height-20);
  letter-spacing: var(--letter-spacing-0-32);
}

@mixin subtitle-4 {
  font-family: var(--font-family-sf);
  font-size: var(--font-size-16);
  font-style: var(--default-font-style);
  font-weight: var(--font-weight-600);
  line-height: var(--line-height-16);
  letter-spacing: var(--letter-spacing-0-32);
}

@mixin body-1 {
  font-family: var(--font-family-sf);
  font-size: var(--font-size-16);
  font-style: var(--default-font-style);
  font-weight: var(--font-weight-400);
  line-height: var(--line-height-20);
}

@mixin body-2 {
  font-family: var(--font-family-sf);
  font-size: var(--font-size-14);
  font-style: var(--default-font-style);
  font-weight: var(--font-weight-400);
  line-height: var(--line-height-16);
  letter-spacing: var(--letter-spacing-0-56);
}

@mixin title-1 {
  font-family: var(--font-family-sf);
  font-size: var(--font-size-12);
  font-style: var(--default-font-style);
  font-weight: var(--font-weight-400);
  line-height: var(--line-height-12);
  letter-spacing: var(--letter-spacing-0-48);
}

@mixin title-2 {
  font-family: var(--font-family-sf);
  font-size: var(--font-size-10);
  font-style: var(--default-font-style);
  font-weight: var(--font-weight-500);
  line-height: var(--line-height-10);
}

@mixin main-balance-emoji {
  font-size: var(--font-size-18);
  line-height: var(--line-height-27);
}

@mixin shop-balance-emoji {
  font-size: var(--font-size-20);
  line-height: var(--line-height-24);
}
